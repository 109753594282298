import React from 'react';

import HubspotForm from 'react-hubspot-form';

const OrderFormCard: React.FunctionComponent = () => (
  <HubspotForm
    portalId="20067109"
    formId="01dd3e77-bac2-41b0-a85b-f0407b12b489"
  />
);

export default OrderFormCard;
