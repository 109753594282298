import React from 'react';
import styled from 'styled-components';
import Main from '../containers/Layout';
import OrderFormCard from '@/components/OrderForm';
import Section from '@/components/Section';
import img from '../assets/img/orderForm.png';
import bg from '../assets/img/orderFormBg.png';
import orderFormContent from '../../content/components/order-form.yml';
import { H2 } from '@/components/Typography';

const Content = styled(Section)`
  background-image: ${(props) => `url(${props.resource})`};
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
  min-height: calc(100vh - 70px - 5rem * 2);
  @media (max-width: 1200px) {
    flex-wrap: wrap;
    height: auto;
  }
`;
const LeftSide = styled.div`
  flex: 1 1 50%;
`;
const FormWrapper = styled.div`
  padding-top: 5rem;
  flex-basis: 50%;
  @media (max-width: 1200px) {
    flex-basis: 100%;
    flex-grow: 1;
    box-shadow: none;
  }
`;
const RightSide = styled.img`
  max-height: 100%;
  padding-left: 1rem;
  @media (max-width: 1200px) {
    max-height: auto;
    width: 100%;
    margin-top: 2rem;
    padding-left: 0;
  }
`;
interface OrderFormPageContent {
  mainSection: {
    title1: string;
    title2: string;
    title3: string;
  };
}
const OrderForm: React.FunctionComponent = () => {
  const { mainSection } = orderFormContent as unknown as OrderFormPageContent;
  return (
    <Main>
      <Content resource={bg}>
        <LeftSide>
          <H2>
            {mainSection.title1}
            {` `}
            <span className="accent-text">{mainSection.title2}</span>
            {` `}
            {mainSection.title3}
          </H2>
          <FormWrapper>
            <OrderFormCard />
          </FormWrapper>
        </LeftSide>
        <RightSide src={img} alt="card screenshot" />
      </Content>
    </Main>
  );
};

export default OrderForm;
